<template>
  <div class="app-container h flex">
    <el-tree ref="ents" :data="enterprises" node-key="id" :props="{label: 'name'}" default-expand-all highlight-current :expand-on-click-node="false" @current-change="handleCurrentChange" style="width: 180px; margin-right: 20px; border-radius: 4px; border: #e7e7e7 solid 1px; overflow: hidden;" v-if="showSubs" />
    <div class="flex v">
      <div class="head-container">
        <el-select v-model="query.enabled" filterable clearable placeholder="状态" class="filter-item" style="width: 90px" @change="toQuery">
          <el-option v-for="item in enabledTypeOptions" :key="item.key" :label="item.label" :value="item.key" />
        </el-select>
        <el-input v-model.trim="query.keyword" clearable placeholder="输入部门名称搜索" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery" />
        <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
        <div style="display: inline-block;margin: 0px 2px;">
          <el-button class="filter-item" size="mini" type="primary" icon="el-icon-plus" @click="add">新增</el-button>
        </div>
        <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
      </div>
      <el-table :data="data" row-key="id" v-loading="loading" highlight-current-row default-expand-all height="200px">
        <el-table-column prop="name" label="部门名称" min-width="180" />
        <el-table-column prop="info" label="部门描述" min-width="360" />
        <el-table-column label="状态" width="100">
          <template slot-scope="scope">
            <el-tag :type="scope.row.enabled ? 'success' : 'info'">{{ scope.row.enabled ? "正常" : "禁用" }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column width="100">
          <div class="row-commands" slot-scope="scope">
            <el-button size="mini" type="text" @click.stop="edit(scope.row)">编辑</el-button>
            <el-button :disabled="!!scope.row.children && !!scope.row.children.length" type="text" size="mini" class="danger" @click.stop="subDelete(scope.row)">删除</el-button>
          </div>
        </el-table-column>
      </el-table>
    </div>
    <eForm ref="form" :is-add="isAdd" @submit="handleFormSubmit" />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import { del } from "@/api/dept";
import { getSub } from "@/api/sysEnterprise";
import { mapGetters } from "vuex";
import eForm from "./form";

export default {
  name: "Dept",
  components: { eForm },
  mixins: [initData],
  data() {
    return {
      columns: [
        {
          text: "名称",
          value: "name",
        },
      ],
      current: null,
      showSubs: false,
      enterprises: [],
      enabledTypeOptions: [
        { key: true, label: "正常" },
        { key: false, label: "禁用" },
      ],
      delLoading: false,
      expand: true,
      query: {
        keyword: null,
        enabled: null,
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  created() {
    getSub().then((res) => {
      this.showSubs = res.length > 0;
      let d = {
        id: this.user.entId,
        name: this.user.entName,
        children: res,
      };
      this.enterprises = [d];
      this.current = d;
      this.$nextTick((_) => {
        this.$refs.ents && this.$refs.ents.setCurrentKey(this.current.id);
      });
      this.init();
    });
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/dept";
      this.params = {
        page: this.page,
        size: this.size,
        sort: "id,desc",
        enabled: this.query.enabled,
        name: this.query.keyword,
        enterpriseId: this.current.id,
      };
      return true;
    },
    handleCurrentChange(row) {
      if (!this.current || this.current.id !== row.id) {
        this.current = row;
        this.toQuery();
      }
    },
    subDelete(row) {
      this.$confirm("您确定要删除该部门吗？", "操作确认", {
        dangerouslyUseHTMLString: true,
        type: "warning",
      }).then((_) => {
        del(row.id).then((res) => {
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500,
          });
          this.toQuery();
        });
      });
    },
    handleFormSubmit(isAdd, d) {
      this.toQuery();
    },
    add() {
      if (this.current) {
        this.isAdd = true;
        this.$refs.form.resetForm(null, this.current.id);
      }
    },
    edit(data) {
      this.isAdd = false;
      let cd = JSON.parse(JSON.stringify(data));
      delete cd.children;
      this.$refs.form.resetForm(cd);
    },
  },
};
</script>

<style scoped>
</style>
